import property from "../images/property.PNG";
import big from "../images/big.PNG";
import dic from "../images/dic.PNG";

export const Bio = {
  name: "Bachir Mecheref",
  roles: ["Full Stack Java Developer", "Programer", "Software Engineer"],
  description:
    "I am a motivated and versatile individual, always eager to take on new challenges. With a passion for learning I am dedicated to delivering high-quality results. With a positive attitude and a growth mindset, I am ready to make a meaningful contribution and achieve great things.",
  github: "https://github.com/mecheref-bachir",
  resume:
    "https://drive.google.com/file/d/1B2w-wI_6Okje1Xeu_e-MrvsdIteB3zGb/view?usp=share_link",
  blog : "https://drive.google.com/file/d/1dakDzoiTPaeMkooJdgyKrMArKJzYxzuw/view?usp=drive_link",
  linkedin: "https://www.linkedin.com/in/mecheref-bachir/",
  insta: "https://www.instagram.com/bachrbachr/",
  facebook: "https://www.facebook.com/karim.krimooo.75",
};

export const skills = [
  {
    title: "Frontend",
    skills: [
      {
        name: "React Js",
        image:
          "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0xMS41IC0xMC4yMzE3NCAyMyAyMC40NjM0OCI+CiAgPHRpdGxlPlJlYWN0IExvZ288L3RpdGxlPgogIDxjaXJjbGUgY3g9IjAiIGN5PSIwIiByPSIyLjA1IiBmaWxsPSIjNjFkYWZiIi8+CiAgPGcgc3Ryb2tlPSIjNjFkYWZiIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIi8+CiAgICA8ZWxsaXBzZSByeD0iMTEiIHJ5PSI0LjIiIHRyYW5zZm9ybT0icm90YXRlKDYwKSIvPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIiB0cmFuc2Zvcm09InJvdGF0ZSgxMjApIi8+CiAgPC9nPgo8L3N2Zz4K",
      },

      {
        name: "HTML",
        image: "https://www.w3.org/html/logo/badge/html5-badge-h-solo.png",
      },
      {
        name: "CSS",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/CSS3_logo_and_wordmark.svg/1452px-CSS3_logo_and_wordmark.svg.png",
      },
      {
        name: "JavaScript",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/JavaScript-logo.png/800px-JavaScript-logo.png",
      },
      {
        name: "Bootstrap",
        image:
          "https://getbootstrap.com/docs/5.3/assets/brand/bootstrap-logo-shadow.png",
      },
    ],
  },
  {
    title: "Backend",
    skills: [
      {
        name: "Spring Boot",
        image:
          "https://www.google.com/url?sa=i&url=https%3A%2F%2Fspring.io%2Ftrademarks&psig=AOvVaw0h95Mk77wzzUorW_TcZ9h3&ust=1697988723070000&source=images&cd=vfe&opi=89978449&ved=0CBEQjRxqFwoTCPCg0Ye7h4IDFQAAAAAdAAAAABAE",
      },

      {
        name: "MySQL",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/mysql/mysql-original-wordmark.svg",
      },
      {
        name: "Postgresql",
        image: "https://www.postgresql.org/media/img/about/press/elephant.png",
      },
      {
        name: "MongoDB",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/mongodb/mongodb-original-wordmark.svg",
      },
    ],
  },

  {
    title: "Others",
    skills: [
      {
        name: "Git",
        image:
          "https://camo.githubusercontent.com/fbfcb9e3dc648adc93bef37c718db16c52f617ad055a26de6dc3c21865c3321d/68747470733a2f2f7777772e766563746f726c6f676f2e7a6f6e652f6c6f676f732f6769742d73636d2f6769742d73636d2d69636f6e2e737667",
      },
      {
        name: "GitHub",
        image:
          "https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png",
      },
      {
        name: "Docker",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/docker/docker-original-wordmark.svg",
      },

      {
        name: "VS Code",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Visual_Studio_Code_1.35_icon.svg/512px-Visual_Studio_Code_1.35_icon.svg.png?20210804221519",
      },
      {
        name: "Postman",
        image:
          "https://camo.githubusercontent.com/93b32389bf746009ca2370de7fe06c3b5146f4c99d99df65994f9ced0ba41685/68747470733a2f2f7777772e766563746f726c6f676f2e7a6f6e652f6c6f676f732f676574706f73746d616e2f676574706f73746d616e2d69636f6e2e737667",
      },
    ],
  },
];

export const experiences = [
  {
    id: 0,
    img: "",
    role: "Software Engineer",
    company: "High Tech Systems",
    date: "Nov 2017 - Nov 2020",
    desc: "Working on Flipr Platforms, managing DevOps, and streamlining the process with automation.",
    skills: ["Java", "Spring Boot", "React", "JavaScript", "PostgrSQL"],
  },

  {
    id: 1,
    img: "",
    role: "Fullstack Java Developer",
    company: "Mobidal",
    date: "Nov 2020 - Feb 2022",
    desc: "Built an employee management full stack web app used Docker and deployed on AWS ec2. I was the top performer in the program.",
    skills: ["Java", "Spring Boot", "React", "JavaScript", "PostgrSQL"],
  },
];

export const education = [
  {
    id: 0,
    img: "https://firebasestorage.googleapis.com/v0/b/flexi-coding.appspot.com/o/Kiit.jpeg?alt=media&token=3839d520-c59d-4341-ad8f-0980c98de4dd",
    school: "Institute Of Electrical and Electronic Engineering -IGEE-",
    date: "Sept 2010 - June 2013",
    grade: "13/20",
    desc: "Bachelor degree in computer engineering, most interesting topics related to electrical and software engineering, most was about low level languages and System Design , i finished my degree with a final project related to Active filters ",
    degree: "Bachelor Degree In Computer Engineering",
  },
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/flexi-coding.appspot.com/o/Kiit.jpeg?alt=media&token=3839d520-c59d-4341-ad8f-0980c98de4dd",
    school: "Institute Of Electrical and Electronic Engineering -IGEE-",
    date: "Sept 2013 - June 2015",
    grade: "13/20",
    desc: "My master degree in computer engineering i focused on advanced topics related to system design and its aplication in real world chalenges using java , i finished My degree with a final project of desgn and implementation of a front end Compiler using java",
    degree: "Master Degree In Computer Engineering",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/flexi-coding.appspot.com/o/methodist.png?alt=media&token=018a1b18-e2dd-4f34-8855-cff1b6b69fd3",
    school: "Maharishi International University",
    date: "Apr 2022  ",
    grade: "3.85/4",
    desc: "Master degree In computer science ",
    degree: "Master Degree In computer Science",
  },
];


export const blog = [
  {
    id: 0,
    desc: "AWS Step Functions is a serverless orchestration service that enables you to coordinate and manage workflows, tasks, and microservices in a visual and efficient manner.",
    
  }
];

export const projects = [
  {
    id: 0,
    title: "Property Management System",
    date: "jul 2022",
    description:
      "Designed and developed a web application where users can manage or search properties for rent and sale. Includes scheduling system and multiple use cases for searching, filtering, and advertising. ",
    image: property,
    tags: ["Java", "Spring Boot", "ReactJS", "PostgreSQL", "Kafka"],
    category: "web app",
    
  },
  {
    id: 1,
    title: "Real-time data processing",
    date: "Oct 2022",
    description:
      " Used MapReduce and bootstrapping technique to perform real-time data processing, calculating statistics and analyzing log data. Implemented pairs and stripes for abstract and relative frequency in co-occurrence matrixes",
    image:
      big,
    tags: [
      "Docker", "Hadoop", "IntelliJ", "Spark", "Java", "MapReduce"
    ],
   
   
  },
  {
    id: 2,
    title: "Online Dictionary",
    date: "Sept 2022",
    description:
      "Created a simple website for an English dictionary with sample phrases. Users can search words and receive definitions",
    image:
      dic,
    tags: ["JavaScript", "NodeJS", "ExpressJS", "Bootstrap", "HTML", "CSS"
      ],
   
  },
];

export const TimeLineData = [
  { year: 2017, text: "Started my journey" },
  { year: 2018, text: "Worked as a freelance developer" },
  { year: 2019, text: "Founded JavaScript Mastery" },
  { year: 2020, text: "Shared my projects with the world" },
  { year: 2021, text: "Started my own platform" },
];
